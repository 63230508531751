<template>
  <div>
    <div>
      <breadcrumb
        name="Cars"
        title="Categories"
        subtitle="View"
        @onTitleClicked="$router.push(`/categories/`)"
      />
    </div>
    <div>
      <vx-card title="Category Info." class="mb-base">
        <vs-row vs-type="flex">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="12"
          >
            <div class="vx-col-5 flex-1 m-auto" id="account-info-col-1">
              <table class="sm-table">
                <tr>
                  <td class="font-semibold p-4">English Name</td>
                  <td class="p-4">{{item.name_en}}</td>
                </tr>
                <!--                <tr>-->
                <!--                  <td class="font-semibold p-4">English Description</td>-->
                <!--                  <td class="p-4">{{item.description_en}}</td>-->
                <!--                </tr>-->
                <tr>
                  <td class="font-semibold p-4">Brand</td>
                  <td class="p-4">{{item.model.brand.name_en}}</td>
                </tr>
                <tr>
                  <td class="font-semibold p-4">Price</td>
                  <td class="p-4">{{item.price}}</td>
                </tr>
              </table>
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="6"
            vs-sm="12"
          >
            <div class="vx-col-5 flex-1 m-auto" id="account-info-col-2">
              <table class="sm-table">
                <tr>
                  <td class="font-semibold p-4">Arabic Name</td>
                  <td class="p-4">{{ item.name_ar}}</td>
                </tr>
                <!--                <tr>-->
                <!--                  <td class="font-semibold p-4">Arabic Description</td>-->
                <!--                  <td class="p-4">{{ item.description_ar}}</td>-->
                <!--                </tr>-->
                <tr>
                  <td class="font-semibold p-4">Model</td>
                  <td class="p-4">{{ item.model.name_en}}</td>
                </tr>
                <tr>
                  <td class="font-semibold p-4">Status</td>
                  <td class="p-4">
                    <vs-switch v-model="item.mobile_active" color="primary" @change="mobile_active_changed = true"/>
                  </td>
                </tr>
              </table>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>

      <vx-card title="Specifications" class="mb-base" style="position: relative">
        <div style="width: 100%; margin-top: -40px" align="right">
          <vs-button
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-plus"
            @click="$router.push(`/categories/add-specs/${item.id}`)"
          >Add New
          </vs-button>
        </div>

        <vs-table ref="table" :sst="true" style="margin-top: 20px" :data="item.specs">
          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Category</vs-th>
            <!--                        <vs-th>Type</vs-th>-->
            <vs-th>Value</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <string-row :data="tr.name_en"/>
              <string-row :data="tr.category.name_en"/>
              <!--                                <string-row :data="tr.type === 'bool' ? 'CheckBox' : 'Text'" />-->
              <string-row :data="getValue(tr.value)"/>
              <actions-row
                :editable="true"
                @onEditClicked="editSpec(tr, indextr)"
                @onDeleteClicked="deleteSpec(tr.pivot_id, indextr)"
              />
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>

      <vx-card title="Accessories" class="mb-base" style="position: relative">
        <div style="width: 100%; margin-top: -40px" align="right">
          <vs-button
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-plus"
            @click="$router.push(`/categories/add-accessory/${item.id}`)"
          >Add New
          </vs-button>
        </div>

        <vs-table ref="table" :sst="true" style="margin-top: 20px" :data="item.accessoreis">
          <template slot="thead">
            <vs-th>Image</vs-th>
            <vs-th>Title English</vs-th>
            <vs-th>Title Arabic</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <image-row :data="tr.image"/>
              <string-row :data="tr.title_en"/>
              <string-row :data="tr.title_ar"/>
              <actions-row
                @onEditClicked="$router.push(`/categories/edit-accessory/${item.id}/${tr.id}`)"
                @onDeleteClicked="deleteAccessory(tr.id, indextr)"
              />
            </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>

      <vx-card title="Services" class="mb-base" v-if="this.showServices">

        <div style="width: 100%; margin-top: -40px" align="right">
          <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addServiceItem" >
            Add New
          </vs-button>
        </div>

        <vs-table
          ref="table"
          :sst="true"
          style="margin-top: 20px"
          :data="itemServices">

          <template slot="thead">
            <vs-th>Name in KMs</vs-th>
            <vs-th>Price</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{data}">
            <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <string-row :data="tr.km"/>
              <string-row :data="tr.price"/>

              <actions-row
                @onDeleteClicked="deleteService(tr.id)"
                @onEditClicked = "editService(tr)"
                :copy = "true"
                @onCopyClicked ="copyService(tr)"/>

            </vs-tr>
            </tbody>
          </template>
        </vs-table>

      </vx-card>

      <vs-prompt
        @cancel="resetValues"
        @accept="updateSpec"
        @close="resetValues"
        :active.sync="activePrompt">
        <div class="con-exemple-prompt">
          Enter Spec Value
          <vs-input v-model="val"/>
        </div>
      </vs-prompt>

      <vs-prompt
        @cancel="resetValues"
        @accept="updateSpec"
        @close="resetValues"
        :active.sync="activePrompt2">
        <div class="con-exemple-prompt">
          Change Availability
          <vs-switch v-model="val"/>
        </div>
      </vs-prompt>

    </div>
  </div>
</template>

<script>
  import Breadcrumb from "../../../../components/general/breadcrumb";
  import StringRow from "../../../../components/table/rows/string-row";
  import ActionsRow from "../../../../components/table/rows/actions-row";
  import ImageRow from "../../../../components/table/rows/image-row";

  export default {
    name: "show-category",
    components: {ImageRow, ActionsRow, StringRow, Breadcrumb},
    // props: {
    //   id: {
    //     required: true,
    //     default: () => {
    //       return '0';
    //     }
    //   }
    // },
    data() {
      return {
        id: "",
        item: {
          name_en: "",
          name_ar: "",
          description_en: "",
          description_ar: "",
          price: "",
          mobile_active: false,
          addServices: false,
          showServices: false,
          servicesCounter: 0,
          modelID: 0,
          mobile_active_changed: false,
          accessoreis: [],
          itemServices: [],
          model: {
            name_en: "",
            brand: {
              name_en: ""
            }
          }
        },
        val: "",
        activePrompt: false,
        activePrompt2: false,
        pivot_id: "",
        index: ""
      };
    },
    methods: {
      changeStatus() {
        this.$vs.loading();
        this.$httpCars.post(`categories/activation/${this.item.id}/mobile`)
          .then(r => {
            this.$vs.loading.close();
            console.log(r);
            if(this.item.mobile_active){
              this.$vs.notify({
                title: 'Success',
                text: 'Category is activated successfully.',
                color: 'primary'
              })
            }else{
              this.$vs.notify({
                title: 'Success',
                text: 'Category is deactivated successfully.',
                color: 'primary'
              })
            }

          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: 'Couldn\'t deactivate category!',
              color: 'danger'
            })
          })
      },
      editSpec(data, index) {
        this.val = data.value;
        this.pivot_id = data.pivot_id;
        this.index = index;
        if(data.type === 'text'){
          this.activePrompt = true;
        }

        if(data.type === 'bool'){
          this.activePrompt2 = true;
        }
      },
      resetValues() {
        this.val = "";
        this.pivot_id = "";
        this.index = "";
      },
      updateSpec() {
        this.$vs.loading();

        let payload = {
          value: this.val
        };

        this.$httpCars
          .post(`categories/spec/${this.pivot_id}`, payload)
          .then(r => {
            console.log({updateSpec_response: r});
            this.$vs.loading.close();
            this.item.specs[this.index].value = this.val;
            this.resetValues();
            this.$vs.notify({
              title: "Success",
              color: "success",
              text: "Spec is updated successfully"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log({updateSpec_error: e});
            this.$vs.notify({
              title: "Error",
              color: "danger",
              text: "Couldn't update this Spec!"
            });
          });
      },
      deleteSpec(id, index) {
        this.$vs.loading();
        this.$httpCars
          .delete(`categories/spec/${id}`)
          .then(r => {
            console.log({deleteSpec_response: r});
            this.$vs.loading.close();
            this.item.specs.splice(index, 1);
            this.$vs.notify({
              title: "Success",
              color: "success",
              text: "Spec is deleted successfully"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log({deleteSpec_error: e});
            this.$vs.notify({
              title: "Error",
              color: "danger",
              text: "Couldn't delete this Spec!"
            });
          });
      },
      deleteAccessory(id, index) {
        this.$vs.loading();
        this.$httpCars
          .delete(`categories/accessories/${id}`)
          .then(r => {
            console.log({deleteSpec_response: r});
            this.$vs.loading.close();
            this.item.accessoreis.splice(index, 1);
            this.$vs.notify({
              title: "Success",
              color: "success",
              text: "Accessory is deleted successfully"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log({deleteSpec_error: e});
            this.$vs.notify({
              title: "Error",
              color: "danger",
              text: "Couldn't delete this Accessory!"
            });
          });
      },

      getValue(value) {
        if (value === true) {
          return "Available";
        } else if (value === false) {
          return "Unavailable";
        }
        return value;
      },
      getCategoryById() {
        this.$vs.loading();
        this.$httpCars
          .get(`categories/${this.id}`)
          .then(r => {
            this.item = r.data.data;
            this.modelID = this.item.model.id;
            this.itemServices = this.item.services;
            this.$vs.loading.close();
            this.showServices = this.modelID == 31;
          })
          .catch(() => {
            //console.log({getCategoryById_error: e});
          });
      },
      addServiceItem() {
        this.$router.push('/categories/add-service/' + this.modelID + '/' + this.item.id);
      },
      updateServices(data) {
        this.itemServices = data.services;

        //TODO:: refactor itemServices to match data structure

        this.addServices = false;
        this.servicesCounter++;
      },
      deleteService(id)
      {
        this.$vs.loading();
        this.$httpCars
          .get(`models/deleteservice/${id}`)
          .then(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Successful operation",
              text: "Add On has been deleted successfully",
              color: 'success'
            });
            this.getModel();
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Something Error 🙁",
              text: "Couldn't delete data on the server",
              color: 'danger'
            });
          })
      },
      editService(item)
      {
        this.$router.push('/categories/edit-service/' +  this.item.id + '/' + item.id);
      },
      copyService(item)
      {

        this.$router.push('/categories/copy-service/' + this.item.id + "/" + item.id);
      },
      addService(service) {
        console.log({service: service});
        this.itemServices.push(service)
      },
      toggleDataSidebar(val = false) {
        this.addServices = val;

      },
    },
    computed: {
      mobile_active() {
        return this.item.mobile_active;
      }
    },
    watch: {
      mobile_active() {
        if (this.mobile_active_changed) {
          this.changeStatus();
        }
      }
    },
    mounted() {
      this.id = this.$route.params.id;
      this.getCategoryById();
    }
  };
</script>

<style scoped lang="scss">
</style>
